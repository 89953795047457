const match = function(str, rule) {
  if (typeof str !== 'string') {
    return ''
  }
  const result = str.match(rule)
  return Array.isArray(result) && result[1] ? result[1] : ''
}

/**
 * 拆分备货信息
 * @param obj
 * @returns {{wares_prep_back_cycle: (string|string|string), wares_prep_day: (string|string|string), wares_prep_back_is: (string), wares_exist_qty: (string|string|string), wares_exist_is: (string|string|string), wares_prep_qty: (string|string|string)}}
 */
export const splitWaresString = function(obj) {
  const { wares_prep, wares_exist } = obj
  let tmp_wares_prep_back_is = match(wares_prep, /是否补货：(\S*) ； /).trim()
  const wares_prep_back_is = tmp_wares_prep_back_is === '是' ? '1' : tmp_wares_prep_back_is === '否' ? '2' : ''
  const result = {
    wares_prep_day: match(wares_prep, /预售天数：(\S*) ； /),
    wares_prep_qty: match(wares_prep, /预售库存：(\S*) ； /),
    wares_prep_back_is,
    wares_prep_back_cycle: match(wares_prep, /补货周期：(\S*) ； /),
    wares_exist_is: match(wares_exist, /预计直播时是否有现货：(\S*) ； /),
    wares_exist_qty: match(wares_exist, /现货库存：(\S*) ； /)
  }
  if (!result.wares_prep_day && !result.wares_prep_qty && !result.wares_prep_back_is && !result.wares_prep_back_cycle) {
    result.wares_prep_qty = wares_prep || ''
  }
  if (!result.wares_exist_is && !result.wares_exist_is) {
    result.wares_exist_qty = wares_exist || ''
  }
  return result
}

/**
 * 拼接备货信息
 * @param obj
 * @returns {{wares_exist: string, wares_prep: string}}
 */
export const jointWaresString = function(obj) {
  const separator = ' ； '
  const {
    wares_prep_day, // 预售15天
    wares_prep_qty, // 预售库存100件
    wares_prep_back_is, //  是否补货：是/否
    wares_prep_back_cycle, // 补货周期为xxx
    wares_exist_is,
    wares_exist_qty
  } = obj
  // `预售15天；预售库存：111；是否补货：是；补货周期为xxx`
  let wares_prep = '', wares_exist = ''
  if (wares_prep_day) {
    wares_prep += `预售天数：${wares_prep_day.indexOf('天') > -1 ? wares_prep_day : wares_prep_day + '天'}${separator}`
  }
  if (wares_prep_qty) {
    wares_prep += `预售库存：${wares_prep_qty}${separator}`
  }
  if (wares_prep_back_is) {
    wares_prep += `是否补货：${parseInt(wares_prep_back_is) === 1 ? '是' : '否'}${separator}`
  }
  if (wares_prep_back_cycle) {
    wares_prep += `补货周期：${wares_prep_back_cycle}${separator}`
  }
  if (wares_exist_is) {
    wares_exist += `预计直播时是否有现货：${wares_exist_is}${separator}`
  }
  if (wares_exist_qty) {
    wares_exist += `现货库存：${wares_exist_qty}${separator}`
  }
  return { wares_prep, wares_exist }
}
