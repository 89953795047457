<template>
  <div style='display: inline-block'>
    <el-select
        v-model='leader'
        filterable
        remote
        ref="users"
        default-first-option
        reserve-keyword
        clearable
        placeholder='请输入：昵称和手机号'
        :remote-method='remoteMethod'
        :loading='loading'>
      <el-option
          v-for='item in options'
          :key='item.value'
          :label='item.label'
          :value='item.value'>
        <span style='float: left'>{{ item.label }}</span>
        <span style='float: right'>{{ item.mobile }}</span>
      </el-option>
    </el-select>
  </div>
</template>

<script>
export default {
  name: 'UserSelect',
  props: {
    leaderId: {
      type: [Number, String]
    },
    keyword: {
      type: String,
      default() {
        return ''
      }
    }
  },
  data() {
    return {
      options: [],
      list: [],
      loading: false
    }
  },
  computed: {
    leader: {
      get() {
        return this.leaderId
      },
      set(val) {
        this.$emit('update:leaderId', val)
        this.changeLeader(val)
      }
    }
  },
  mounted() {
  },
  methods: {
    selectData(name) {
      this.remoteMethod(name)
      this.$refs['users'].toggleMenu()
    },
    async remoteMethod(query) {
      this.options = []
      if (query !== '') {
        this.loading = true
        await this.getOptions(query)
        this.loading = false
      } else {
        this.options = []
      }
    },
    async getOptions(query) {
      let list = await this.$api.searchUser(query)
      Object.keys(list).forEach((value) => {
        let item = list[value]
        this.options.push({
          value: `${item.id}`,
          label: `${item.nickname}`,
          mobile: `${item.mobile.substr(0, 3)}****${item.mobile.substr(7)}`
        })
      })
    },
    changeLeader(val) {
      if (val && this.options.length) {
        let option = this.options.find(value => value.value === val)
        this.$emit('update:keyword', option.label || '')
        this.$emit('selected', option)
      } else {
        this.$emit('update:keyword', '')
        this.$emit('selected', {})
      }

    }
  }
}
</script>

<style scoped>

</style>
